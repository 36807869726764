
import http from "@/http";
import Vue from "vue";

import { mdiDelete } from "@mdi/js";
import { Document, Snackbar } from "@/interfaces";

export interface DataType {
  document?: Document;
  name: string;
  url: string;
  files: File[];
}

export default Vue.extend({
  data(): DataType {
    return {
      document: undefined,
      name: "",
      url: "",
      files: [],
    };
  },
  computed: {
    breadcrumbs() {
      let text = "";
      if (this.document) {
        text = `# ${this.document.id} ${this.document.name}`;
      }
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "ドキュメント",
          disabled: false,
          to: "/documents",
        },
        {
          text,
          disabled: true,
        },
      ];
    },
    icon() {
      return {
        mdiDelete,
      };
    },
  },
  async mounted() {
    const { id } = this.$route.params;

    const url = `/documents/${id}`;

    const { data } = await http.get<Document>(url);

    this.document = data;

    this.name = this.document.name;
    this.url = this.document.url;
  },

  methods: {
    async click() {
      if (this.document == null) {
        return;
      }

      const formData = new FormData();

      if (this.files.length > 0) {
        const file = this.files[0];

        formData.append("file", file);
      }

      formData.append("name", this.name);

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      try {
        const url = `/documents/${this.document.id}`;
        const res = await http.post(url, formData, {
          headers,
        });
        await this.$router.push(`/documents`);
      } catch (error) {
        const snackbar: Snackbar = {
          show: true,
          content: "ファイルに不備があるようです",
          color: "success",
        };

        this.$store.commit("setSnackbar", snackbar);
      }
    },

    async remove() {
      if (!window.confirm("本当に削除しますか?")) {
        return;
      }

      const { id } = this.$route.params;

      const url = `/documents/${id}`;

      await http.delete(url);

      this.$router.push("/documents");
    },
  },
});
